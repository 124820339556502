import React, { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";
import useInput from "../hooks/useInput";
import axiosInstance from "../utils/AxiosHelper";
import "../Auth.css";

function Login() {
	const authCtx = useContext(AuthContext);
	const navigate = useNavigate();

	const [loginWithOtp, setLoginWithOtp] = useState(false);
	const [isOtpPage, setisOtpPage] = useState(false);
	const [otp, setOtp] = useState(null);

	let {
		enteredInput: enteredEmail,
		inputValid: emailInputIsValid,
		inputInvalid: emailInputIsInvalid,
		blurHandler: emailInputBlurHandler,
		changeHandler: emailInputChangeHandler,
		reset: emailInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredPassword,
		inputValid: passwordInputIsValid,
		inputInvalid: passwordInputIsInvalid,
		blurHandler: passwordInputBlurHandler,
		changeHandler: passwordInputChangeHandler,
		reset: passwordInputReset,
	} = useInput("", (value) => value !== "" && value.length >= 5);

	const loginHandler = async (e) => {
		e.preventDefault();

		if (isOtpPage) {
			const response = await axiosInstance.post("user/verifyViaOTP", {
				email: enteredEmail,
				OTP: otp,
			});

			if (response.data.status === "success") {
				toast.success("Successfully logged in!");
				localStorage.setItem("token", response.data.token);
				localStorage.setItem("user", JSON.stringify(response.data.data.user));
				authCtx.login(response.data.data.user);
				navigate("/admin/dashboard");
			}
		} else {
			if (loginWithOtp) {
				// User will login with OTP.
				emailInputBlurHandler();

				if (!enteredEmail) {
					return;
				}

				const response = await axiosInstance.post("user/loginWithOTP", {
					email: enteredEmail,
				});

				if (response.data.status === "success") {
					toast.success("OTP sent Successfully");
					setisOtpPage(true);
				}
			} else {
				// User will login with Email.
				emailInputBlurHandler();
				passwordInputBlurHandler();

				if (!enteredEmail || !(enteredPassword.length >= 5)) {
					return;
				}

				const response = await axiosInstance.post("auth/login", {
					email_id: enteredEmail,
					password: enteredPassword,
				});

				if (response.data.status === true) {
					toast.success("Successfully logged in!");
					localStorage.setItem("token", response.data.data.token);
					localStorage.setItem(
						"user",
						JSON.stringify(response.data.data.result)
					);
					authCtx.login(response.data.data.result);
					navigate("/admin/dashboard");
				}
			}
		}
	};

	const handleChange = (otp) => {
		setOtp(otp);
	};

	return (
		<div id="app">
			<div className="container">
				<header>
					<div className={"header-headings"}>
						<span>Sign in to your account</span>
					</div>
				</header>

				<form className="account-form" onSubmit={loginHandler}>
					{!isOtpPage ? (
						<div className={"account-form-fields"}>
							<input
								id="email"
								name="email"
								type="email"
								placeholder="E-mail"
								value={enteredEmail}
								onChange={emailInputChangeHandler}
								onBlur={emailInputBlurHandler}
								// error={emailInputIsInvalid}
							/>
							{emailInputIsInvalid && (
								<p className="error-text">Please enter valid email address!</p>
							)}
							{!loginWithOtp && (
								<>
									<input
										id="password"
										name="password"
										type="password"
										placeholder="Password"
										value={enteredPassword}
										onChange={passwordInputChangeHandler}
										onBlur={passwordInputBlurHandler}
									/>
									{passwordInputIsInvalid && (
										<p className="error-text">
											Please enter atleast 8 character!
										</p>
									)}
								</>
							)}
						</div>
					) : (
						<OtpInput
							className="otpInput"
							value={otp}
							onChange={handleChange}
							numInputs={6}
							isInputNum
						/>
					)}
					<button className="btn-submit-form" type="submit">
						{!isOtpPage && (loginWithOtp ? "Send OTP" : "Sign in")}
						{isOtpPage && "Verify OTP"}
					</button>
				</form>

				<div className="forgot-passowrd">
					<Link to="/forgot-password">Forgot password</Link>
				</div>

				{/* {!isOtpPage && (
					<div
						className="forgot-passowrd"
						onClick={() => {
							setLoginWithOtp((prev) => !prev);
						}}
						style={{ cursor: "pointer" }}
					>
						{!loginWithOtp ? "Login with OTP" : "Login with Email"}
					</div>
				)} */}

				{/* <div className="btn-submit-form" style={{ marginTop: "100px" }}>
					<Link to="/signUp">Create new account</Link>
				</div> */}
			</div>
		</div>
	);
}

export default Login;
